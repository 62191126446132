/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
    PhoneMask,
    CurrencyMask,
    IntegerMask,
} from './MTextFieldMasks';

MTextField.propTypes = {
    GridProps: PropTypes.object,
    sx: PropTypes.object,
    label: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    validate: PropTypes.func,
    error: PropTypes.string,
    minRows: PropTypes.number,
    type: PropTypes.string,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onControlEnter: PropTypes.func,
    normalize: PropTypes.oneOf(["phone", "email", "currency", "postal_code", "integer"]),
    value: PropTypes.string,
}

/**
 * 
 * @param {{
 *     GridProps: {},
 *     control: any,
 *     sx: {},
 *     label: string,
 *     multiline: bool,
 *     validate: (newValue: string) => string | undefined,
 *     minRows: number,
 *     required: bool,
 *     required: bool,
 *     type: bool,
 *     error: string,
 *     fullWidth: bool,
 *     autoFocus: bool,
 *     onControlEnter: (...args: any[]) => void,
 *     normalize: string,
 *     value: string | number,
 * }} props
 * @returns 
 */
function MTextField({ onControlEnter, error, type, autoFocus, value, onChange, validate, normalize, placeholder, helperText, GridProps = { xs: 12 }, disabled, sx, label, multiline, minRows, required, fullWidth }) {

    const [displayError, setDisplayError] = useState(undefined);

    useEffect(() => {
        const newError = validate ? validate(value) : error;
        if (newError !== displayError) {
            setDisplayError(newError);
        }
    }, [value, validate, error]);


    // function handleChange(event, ...other) {
    //     const newValue = type === "number" || normalize === "currency" ? StringParser.normalizeNumeric(event.target.value) : event.target.value;

    //     const newEvent = {
    //         ...event,
    //         target: { value: newValue }
    //     };
    //     console.log("change event", newEvent, other);
    //     onChange(newEvent);
    // }

    const onKeyDown = (event) => {
        if (event.key !== 'Control' && event.ctrlKey && event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            onControlEnter();
        }
    }

    const { fullRow, ...gridRest } = GridProps;

    const inputProps = useMemo(() => {
        switch (normalize) {
            case "phone":
                return { inputComponent: PhoneMask, };
            case "currency":
                return { inputComponent: CurrencyMask, };
            case "integer":
                return { inputComponent: IntegerMask, };
            default:
                return {};
        }
    }, [normalize])

    return <Grid item {...gridRest}>
        <TextField
            onKeyDown={onKeyDown}
            type={type}
            autoFocus={autoFocus}
            margin="dense"
            helperText={helperText || displayError ? <Typography variant="inherit" sx={{ color: "red" }}>{displayError}</Typography> : undefined}
            value={value}
            InputLabelProps={{ shrink: !!value }}
            onChange={onChange}
            placeholder={placeholder}
            label={label}
            disabled={disabled}
            fullWidth={!!GridProps || fullWidth !== false}
            multiline={multiline}
            minRows={minRows}
            required={required}
            slotProps={{ input: inputProps }}
            sx={{
                ...sx,
                backgroundColor: !!multiline && "#fff", // needed for multiline padding
                borderRadius: 1,
            }}
        />
    </Grid>;
};




export default MTextField