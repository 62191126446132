import { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../configs';
import { TankDeliveryEventContext, LayoutContext, LookupContext, useSiteContext } from '../../contexts';
import { dateHelper, useNavigateTo } from '../../helpers';
import { useSiteView } from '../../helpers/useViews';
import { MForm, MFormTextField } from '../@material-extend';
import MFormDateTimePicker from '../@material-extend/MFormDateTimePicker';
import PageText from '../templates/PageText';
import MFormAutocomplete from '../@material-extend/MFormAutocomplete';
import { useAuth0 } from '@auth0/auth0-react';

const defaultValues = {
  manual_entry: true,
  tank_delivery_event_active: true,
  tank_delivery_begin_timestamp: null,
  tank_level_begin: null,
  tank_level_end: null,
  delivery_pulses: null,
  tank_delivery_duration: null,
  tank_delivery_quantity: null,
  jira_ticket_number: null,
  fill_quantity_reviewed: false,
}

export function TankDeliveryEventForm(props) {
  const { upsertEntity } = useContext(TankDeliveryEventContext);
  const layout = useContext(LayoutContext);
  const [, navigateTo] = useNavigateTo();
  const lookup = useContext(LookupContext);
  const siteContext = useSiteContext();
  const siteView = useSiteView();
  const { user } = useAuth0();

  const form = useForm({
    defaultValues: defaultValues,
  });

  const handleSelectSite = (site) => {
    siteContext.setActiveEntity(site.site_uuid);
    siteView.refreshView();
  }

  function isOptionEqualToValue(option, value) {
    if (value === "") {
      return true;
    }
    return option.site_uuid.localeCompare(value.site_uuid);
  }

  const handleValidateForm = (data) => {
    let errorMessage = null;

    if (!data.site_uuid) {
      errorMessage = "Select a site for delivery"
    }
    else if (!data.tank_delivery_begin_timestamp) {
      errorMessage = "Select a begin timestamp"
    }
    else if (!data.tank_delivery_duration) {
      data.tank_delivery_duration = null;
      errorMessage = "Duration required"
    }
    else if (!data.tank_level_begin) {
      data.tank_level_begin = null;
      errorMessage = "Tank level begin required"
    }
    else if (!data.tank_level_end) {
      data.tank_level_end = null;
      errorMessage = "Tank level end required"
    }
    else if (!data.tank_delivery_quantity) {
      data.tank_delivery_quantity = null;
      errorMessage = "Delivery quantity required"
    }
    else if (!data.delivery_pulses) {
      data.delivery_pulses = null;
      errorMessage = "Delivery pulses required"
    }

    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  function onSubmit(data) {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }

    // manual_entry is set to true in form defaultValues
    // event_active is set to true in form defaultValues

    if (data.jira_ticket_number) {
      data.jira_ticket_number = data.jira_ticket_number.toUpperCase();
    }

    let beginDate = data.tank_delivery_begin_timestamp
    let minutes = data.tank_delivery_duration
    data.tank_delivery_end_timestamp = new Date(beginDate.getTime() + minutes * 60000)
    data.manual_update_by = user.nickname

    return upsertEntity(data).then(() => {
      navigateTo(routeConfig.tank_fill_table);
    })
  }

  useEffect(() => {
    if (props.form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  return (
    <MForm
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      form={form}
    >
      <MFormAutocomplete
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="site_uuid"
        label="Select Site*"
        options={lookup.values.site_hierarchy}
        GridProps={{
          xs: 9,
        }}
        onOptionSelect={handleSelectSite}
        isOptionEqualToValue={isOptionEqualToValue}
      />
      <PageText
        label="Unit of Measure"
        GridProps={{ xs: 3, md: 3, }}
        body={siteView?.unit_of_measure || "-"}
        align="center"
      />

      {/* ----------------------------- Detail Fields ----------------------------- */}
      <MFormDateTimePicker
        {...{
          minDate: undefined,
          maxDate: dateHelper.getToday(),
        }}
        GridProps={{ xs: 6 }}
        name="tank_delivery_begin_timestamp"
        label="Delivery Begin Time"
        clearable
        autoFocus
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />

      <MFormTextField
        name="tank_delivery_duration"
        label="Delivery Duration (in minutes)"
        GridProps={{ item: true, xs: 6 }}
        required
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <PageText
        body={"Tank levels and quantity are in site unit of measure"}
      />
      <MFormTextField
        name="tank_level_begin"
        label="Tank Level Begin"
        GridProps={{ item: true, xs: 6 }}
        required
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />

      <MFormTextField
        name="tank_level_end"
        label="Tank Level End"
        GridProps={{ item: true, xs: 6 }}
        required
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />

      <MFormTextField
        name="tank_delivery_quantity"
        label="Delivery Quantity"
        GridProps={{ item: true, xs: 6 }}
        required
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="delivery_pulses"
        label="Delivery Pulses"
        GridProps={{ item: true, xs: 6 }}
        required
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="jira_ticket_number"
        label="JIRA Ticket Number"
        GridProps={{ item: true, xs: 6 }}
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />

    </MForm>
  );
}