/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../../configs';
import { MForm, MFormTextField, MFormSelect } from '../../@material-extend';
import { LookupContext, useSiteContext } from '../../../contexts';
import { useNavigateTo } from '../../../helpers';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';

const defaultValues = {
  number_of_lanes: null,
  piping_type: null,
  application: null,
  plant_type: null,
  nat_box_count: null,
  access_instructions: null,
  delivery_restricted: false,
}

export function SitePlantDetailsForm(props) {
  const { form_data } = props
  const lookup = useContext(LookupContext);
  const siteContext = useSiteContext();
  const [params, navigateTo] = useNavigateTo();
  const [showBackdrop, setShowBackdrop] = useState(false);

  const form = useForm({
    defaultValues: defaultValues,
  });

  function onSubmit(data) {

    setShowBackdrop(true);
    siteContext.upsertEntity(data)
      .then(newSiteSuccess => {
        console.log("Saved Site", newSiteSuccess);
        navigateTo(routeConfig.site_view);
        setShowBackdrop(false);
      }).catch(() => {
        setShowBackdrop(false);
      });
  }

  useEffect(() => {
    if (params.business_unit_uuid && !props.form_data?.business_unit_uuid) {
      form.setValue("business_unit_uuid", params.business_unit_uuid);
    }
  }, [form, params.business_unit_uuid, props.form_data]);

  useEffect(() => {
    if (!!form_data) {
      form.reset(props.form_data)
    }
  }, [props.form_data])

  return (<MForm
    defaultValues={defaultValues}
    onSubmit={onSubmit}
    form={form}
  >
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showBackdrop}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

    {/* ----------------------------- Plant Details ----------------------------- */}
    <Restricted to={[permissions.WRITE_PLANT_DETAILS]}>
      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="application"
        options={lookup.values.application}
        includeAll="-- Unselect --"
        label="Application Type"
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="piping_type"
        includeAll="-- Unselect --"
        options={lookup.values.piping_type}
        label="Piping Type"
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormSelect
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="plant_type"
        includeAll="-- Unselect --"
        options={lookup.values.plant_type}
        label="Plant Type"
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormTextField
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="number_of_lanes"
        normalize="integer"
        label="Number of Lanes"
        GridProps={{
          xs: 12,
          md: 6,
          lg: 6,
        }}
      />
      <MFormTextField
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="nat_box_count"
        normalize="integer"
        label="Number of NAT Boxes"
        GridProps={{
          xs: 12,
          md: 6,
          lg: 6,
        }}
      />
    </Restricted>
    <Grid item xs={12} sx={{ p: 2 }} />
  </MForm >
  );
}