import { useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { routeConfig } from '../../configs';
import { TankDeliveryEventContext, LayoutContext } from '../../contexts';
import { dateHelper, useNavigateTo } from '../../helpers';
import { MForm, MFormCheckbox, MFormDatePicker, MFormTextField } from '../@material-extend';
import { useAuth0 } from '@auth0/auth0-react';

const defaultValues = {
  tank_level_begin_manual_t1: null,
  tank_level_end_manual_t1: null,
}

export function TankDeliveryCalibrationForm(props) {
  const { upsertEntity } = useContext(TankDeliveryEventContext);
  const layout = useContext(LayoutContext);
  const [, navigateTo] = useNavigateTo();
  const { user } = useAuth0();


  const form = useForm({
    defaultValues: defaultValues,
  });

  const handleValidateForm = (data) => {
    let errorMessage = null;

    if (data.tank_level_begin_manual_t1) {
      data.tank_level_begin_manual_t1 = parseInt(data.tank_level_begin_manual_t1)
    }
    if (data.tank_level_begin_manual_t2) {
      data.tank_level_begin_manual_t2 = parseInt(data.tank_level_begin_manual_t2)
    }
    if (data.tank_level_end_manual_t1) {
      data.tank_level_end_manual_t1 = parseInt(data.tank_level_end_manual_t1)
    }
    if (data.tank_level_end_manual_t2) {
      data.tank_level_end_manual_t2 = parseInt(data.tank_level_end_manual_t2)
    }
    if (data.pre_fill_ai_t1) {
      data.pre_fill_ai_t1 = parseInt(data.pre_fill_ai_t1)
    }
    if (data.pre_fill_ai_t2) {
      data.pre_fill_ai_t2 = parseInt(data.pre_fill_ai_t2)
    }
    if (data.post_fill_ai_t1) {
      data.post_fill_ai_t1 = parseInt(data.post_fill_ai_t1)
    }
    if (data.post_fill_ai_t2) {
      data.post_fill_ai_t2 = parseInt(data.post_fill_ai_t2)
    }

    if (data.tank_calibration_date_t1) {
      data.tank_calibration_date_t1 = dateHelper.formatDate(data.tank_calibration_date_t1);
    }
    if (data.tank_calibration_date_t2) {
      data.tank_calibration_date_t2 = dateHelper.formatDate(data.tank_calibration_date_t2);
    }
    if (data.tank_delivery_calibration_note) {
      data.tank_delivery_calibration_note = data.tank_delivery_calibration_note.substring(0, 512);
    }
    if (errorMessage) {
      layout.handleError(errorMessage);
      return false;
    }
    return true;
  }

  function onSubmit(data) {

    const isValidForm = handleValidateForm(data);
    if (!isValidForm) {
      return;
    }

    data.manual_update_by = user.nickname

    return upsertEntity({ ...data, manual_update_by: user.nickname, update_history: 'Calibration Update' }).then(() => {
      navigateTo(routeConfig.tank_fill_event_view);
    })
  }

  useEffect(() => {
    if (props.form_data) {
      if (props.form_data.tank_calibration_date_t1) {
        props.form_data.tank_calibration_date_t1 = new Date(props.form_data.tank_calibration_date_t1.replace(/-/g, '/'));
      }
      if (props.form_data.tank_calibration_date_t2) {
        props.form_data.tank_calibration_date_t2 = new Date(props.form_data.tank_calibration_date_t2.replace(/-/g, '/'));
      }
      form.reset(props.form_data)
    }
  }, [props.form_data, form])

  return (
    <MForm
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      form={form}
    >
      <MFormTextField
        name="tank_delivery_calibration_note"
        label="Calibration Note"
        GridProps={{ item: true, xs: 12 }}
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="tank_level_begin_manual_t1"
        label="Manual Tank Level Begin Tank 1"
        GridProps={{ item: true, xs: 6 }}
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="tank_level_begin_manual_t2"
        label="Manual Tank Level Begin Tank 2"
        GridProps={{ item: true, xs: 6 }}
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="tank_level_end_manual_t1"
        label="Manual Tank Level End Tank 1"
        GridProps={{ item: true, xs: 6 }}
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="tank_level_end_manual_t2"
        label="Manual Tank Level End Tank 2"
        GridProps={{ item: true, xs: 6 }}
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="pre_fill_ai_t1"
        label="Pre Fill AI Tank 1"
        GridProps={{ item: true, xs: 6 }}
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="pre_fill_ai_t2"
        label="Pre Fill AI Tank 2"
        GridProps={{ item: true, xs: 6 }}
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="post_fill_ai_t1"
        label="Post Fill AI Tank 1"
        GridProps={{ item: true, xs: 6 }}
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="post_fill_ai_t2"
        label="Post Fill AI Tank 2"
        GridProps={{ item: true, xs: 6 }}
        normalize="integer"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="current_unit_per_ai_t1"
        label="Current Unit Per AI Tank 1"
        GridProps={{ item: true, xs: 6 }}
        type="number"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormTextField
        name="current_unit_per_ai_t2"
        label="Current Unit Per AI Tank 2"
        GridProps={{ item: true, xs: 6 }}
        type="number"
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
      />
      <MFormDatePicker
        name="tank_calibration_date_t1"
        label="Calibration Date Tank 1"
        clearable
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormDatePicker
        name="tank_calibration_date_t2"
        label="Calibration Date Tank 2"
        clearable
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        GridProps={{
          xs: 12,
          md: 6,
        }}
      />
      <MFormCheckbox
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="hot_fill_t1"
        row
        label="Hot Fill Tank 1"
        GridProps={{
          xs: 6,
          sx: { pl: 2 }
        }}
      />
      <MFormCheckbox
        onControlEnter={form.handleSubmit(onSubmit)}
        control={form.control}
        name="hot_fill_t2"
        row
        label="Hot Fill Tank 2"
        GridProps={{
          xs: 6,
          sx: { pl: 2 }
        }}
      />
    </MForm>
  );
}