import Page from '../../templates/Page';
import PageSquare from '../../templates/PageSquare';
import { MButton } from '../../@material-extend';
import { LayoutContext } from '../../../contexts';
import { useContext } from 'react';
import { routeConfig } from '../../../configs';
import { useNavigateTo } from '../../../helpers';
import PageText from '../../templates/PageText';
import Restricted from '../../Restricted';
import { permissions } from '../../../configs/permissionConstants';

export function Dashboard() {
  const [, navigateTo] = useNavigateTo();
  const { windowDimensions } = useContext(LayoutContext);

  const navigateToSiteAdmin = () => {
    navigateTo(routeConfig.site_admin)
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <Page
      title="Dashboard"
      hideHeader
      spacing={windowDimensions.isMobile ? 0 : 4}
      direction="row"
      justifyContent="center"
    >
      <PageSquare
        gridX={4}
        title={"NITROvision Tools"}
      >
          <Restricted to={[permissions.VIEW_TOOL_JIRA]}>
            <MButton
              GridProps
              size="large"
              variant="outlined"
              color="primary"
              children={"Jira"}
              onClick={() => openInNewTab('https://nitrocrete.atlassian.net/plugins/servlet/ac/doitbetter.calendar/calendar-page?project.key=NV&project.id=10000')}
            />
            <PageText
              align='center'
              body={"Business tool orders and service tickets. Manage nitrogen orders and service tickets."}
              sx={{ p: 2, mb: windowDimensions.isMobile ? 0 : 2 }}
            />
          </Restricted>
          <Restricted to={[permissions.VIEW_TOOL_TABLEAU]}>
            <MButton
              GridProps
              size="large"
              variant="outlined"
              color="primary"
              children={"Tableau"}
              onClick={() => openInNewTab('https://10az.online.tableau.com/#/site/nitrocrete/explore')}
            />
            <PageText
              align='center'
              body={"Reporting tool for viewing financial progress, usage and tank fills."}
              sx={{ p: 2, mb: windowDimensions.isMobile ? 0 : 2 }}
            />
          </Restricted>
          <Restricted to={[permissions.VIEW_TOOL_SITE_MANAGER]}>
            <MButton
              GridProps
              size="large"
              variant="outlined"
              color="primary"
              children={"Site Manager"}
              onClick={navigateToSiteAdmin}
            />
            <PageText
              align='center'
              body={"Data entry and viewing of sites, suppliers, tank fills."}
              sx={{ p: 2, mb: windowDimensions.isMobile ? 0 : 2 }}
            />
          </Restricted>
          <Restricted to={[permissions.VIEW_TOOL_SITE_MANAGER]}>
            <MButton
              GridProps
              size="large"
              variant="outlined"
              color="primary"
              children={"Grafana Dashboard"}
              onClick={() => openInNewTab('https://nitrocrete.grafana.net/dashboards')}
            />
            <PageText
              align='center'
              body={"Dashboard to visualize data."}
              sx={{ p: 2, mb: windowDimensions.isMobile ? 0 : 2 }}
            />
          </Restricted>
      </PageSquare>
    </Page>
  );
}