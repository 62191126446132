/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import {
    siteService,
    parentCompanyService,
    businessUnitService,
    regionalCompanyService,
    businessUnitContactService,
    siteContactService,
    representativeAssignmentService,
    customerContractService,
    supplierContractService,
} from '../services';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorContext from './ErrorContext';
import { useSnackbar } from 'notistack';
import { entityConfig } from '../configs';
import { LayoutContext } from './LayoutContext';
import { useAuth0 } from '@auth0/auth0-react';


const defaultValue = {
    isLoading: false,
    dev_control: {
        show_delete_icon_on_lists: false,
    },
    entities: {
        parent: [],
        region: [],
        business: [],
        business_unit_contact: null,
        site: [],
        site_contact: null,
        representative_assignment: null,
        customer_contract: null,
        supplier_contract: null,
        active_parent: null,
        active_region: null,
        active_business: null,
        active_business_unit_contact: null,
        active_site: null,
        active_site_contact: null,
        active_representative_assignment: null,
        active_customer_contract: null,
        active_supplier_contract: null,
    },
    params: {
        parent_company_uuid: "",
        regional_company_uuid: "",
        business_unit_uuid: "",
        site_uuid: "",
        site_contact_uuid: "",
        business_unit_contact_uuid: "",
        representative_assignment_uuid: "",
        customer_contract_uuid: "",
        supplier_contract_uuid: "",
    },
    windowDimensions: {
        isMobile: false,
        isDesktop: false,
        isXs: false,
        isSm: false,
        isMd: false,
        isLg: false,
        isXl: false,
        size: 0,
        isXlUp: false,
        isLgUp: false,
        isMdUp: false,
        isSmUp: false,
        isXsUp: false,
    },
    createEntity: (token, entityType, data) => { },
    deleteEntity: (token, entityType, uuid) => new Promise(() => "Delete", () => false),
    updateEntity: (token, entityType, uuid, formData) => { },
    getChildUuids: (entity) => [],
    getHierarchyParams: (entity) => ({
        parent_company_uuid: undefined,
        regional_company_uuid: undefined,
        business_unit_uuid: undefined,
        site_uuid: undefined,
    })
}

export const CustomerAdminContext = React.createContext(defaultValue);

/**
 * Manages entity state for customer admin.
 * > Dependent on ErrorContext
 * @param {*} props 
 * @returns 
 */
export const CustomerAdminContextProvider = (props) => {
    const { isLoading, setIsLoading, windowDimensions } = useContext(LayoutContext);

    /* -------------------------------------------------------------------------- */
    /*                               Feedback Utils                               */
    /* -------------------------------------------------------------------------- */
    const { enqueueSnackbar } = useSnackbar();
    const handleSuccess = (message) => {
        setIsLoading(false);
        if (message)
            enqueueSnackbar(message, { variant: "success" });
    }
    const { pushError } = useContext(ErrorContext);
    const handleError = (message, err) => {
        setIsLoading(false);
        pushError({ message: `${message} : ${err}` });
        enqueueSnackbar(message, { variant: "error" });
    }
    const { getAccessTokenSilently } = useAuth0();



    /* -------------------------------------------------------------------------- */
    /*                              Entity List State                             */
    /* -------------------------------------------------------------------------- */
    const [parentCompanies, setParentCompanies] = useState([]);
    const [regionalCompanies, setRegionalCompanies] = useState([]);
    const [businessUnits, setBusinessUnits] = useState([]);
    const [businessUnitContacts, setBusinessUnitContacts] = useState([]);
    const [sites, setSites] = useState([]);
    const [siteContacts, setSiteContacts] = useState([]);
    const [representativeAssignments, setRepresentativeAssignments] = useState([])
    const [customerContracts, setCustomerContracts] = useState([])
    const [supplierContracts, setSupplierContracts] = useState([])



    /**
     * Calls a state setter based on entity type
     * @param {EntityType.parent | EntityType.region | EntityType.business | EntityType.site} entityType 
     * @param {Object[]} newEntities 
     */
    const updateSingleEntityForType = (entity) => {
        const entityType = entityConfig.getType(entity);

        switch (entityType) {
            case entityConfig.type.parent_company: {
                const newEntities = parentCompanies.slice();
                const targetIndex = newEntities.findIndex(e => e.parent_company_uuid === entity.parent_company_uuid);
                if (targetIndex !== -1) {
                    newEntities[targetIndex] = {
                        ...newEntities[targetIndex],
                        ...entity
                    };
                } else {
                    newEntities.push(entity);
                }
                setParentCompanies(newEntities);
                break;
            }
            case entityConfig.type.regional_company: {
                const newEntities = regionalCompanies.slice();
                const targetIndex = newEntities.findIndex(e => e.regional_company_uuid === entity.regional_company_uuid);
                if (targetIndex !== -1) {
                    newEntities[targetIndex] = {
                        ...newEntities[targetIndex],
                        ...entity
                    };
                } else {
                    newEntities.push(entity);
                }
                setRegionalCompanies(newEntities);
                break;
            }
            case entityConfig.type.business_unit: {
                const newEntities = businessUnits.slice();
                const targetIndex = newEntities.findIndex(e => e.business_unit_uuid === entity.business_unit_uuid);
                if (targetIndex !== -1) {
                    newEntities[targetIndex] = {
                        ...newEntities[targetIndex],
                        ...entity
                    };
                } else {
                    newEntities.push(entity);
                }
                setBusinessUnits(newEntities);
                break;
            }
            case entityConfig.type.business_unit_contact: {
                const newEntities = businessUnitContacts.slice();
                const targetIndex = newEntities.findIndex(e => e.business_unit_contact_uuid === entity.business_unit_contact_uuid);
                if (targetIndex !== -1) {
                    newEntities[targetIndex] = {
                        ...newEntities[targetIndex],
                        ...entity
                    };
                } else {
                    newEntities.push(entity);
                }
                setBusinessUnitContacts(newEntities);
                break;
            }
            case entityConfig.type.site: {
                const newEntities = sites.slice();
                const targetIndex = newEntities.findIndex(e => e.site_uuid === entity.site_uuid);
                if (targetIndex !== -1) {
                    newEntities[targetIndex] = {
                        ...newEntities[targetIndex],
                        ...entity
                    };
                } else {
                    newEntities.push(entity);
                }
                setSites(newEntities);
                break;
            }
            case entityConfig.type.site_contact: {
                const newEntities = siteContacts.slice();
                const targetIndex = newEntities.findIndex(e => e.site_contact_uuid === entity.site_contact_uuid);
                if (targetIndex !== -1) {
                    newEntities[targetIndex] = {
                        ...newEntities[targetIndex],
                        ...entity
                    };
                } else {
                    newEntities.push(entity);
                }
                setSiteContacts(newEntities);
                break;
            }
            case entityConfig.type.representative_assignment: {
                const newEntities = representativeAssignments.slice();
                const targetIndex = newEntities.findIndex(e => e.representative_assignment_uuid === entity.representative_assignment_uuid);
                if (targetIndex !== -1) {
                    newEntities[targetIndex] = {
                        ...newEntities[targetIndex],
                        ...entity
                    };
                } else {
                    newEntities.push(entity);
                }
                setRepresentativeAssignments(newEntities);
                break;
            }
            case entityConfig.type.customer_contract: {
              const newEntities = customerContracts.slice();
              const targetIndex = newEntities.findIndex(e => e.customer_contract_uuid === entity.customer_contract_uuid);
              if (targetIndex !== -1) {
                  newEntities[targetIndex] = {
                      ...newEntities[targetIndex],
                      ...entity
                  };
              } else {
                  newEntities.push(entity);
              }
              setCustomerContracts(newEntities);
              break;
          }
          case entityConfig.type.supplier_contract: {
            const newEntities = supplierContracts.slice();
            const targetIndex = newEntities.findIndex(e => e.supplier_contract_uuid === entity.supplier_contract_uuid);
            if (targetIndex !== -1) {
                newEntities[targetIndex] = {
                    ...newEntities[targetIndex],
                    ...entity
                };
            } else {
                newEntities.push(entity);
            }
            setSupplierContracts(newEntities);
            break;
          }
            default:
                break;
        }
    }

    /**
     * Calls a state setter based on entity type
     * @param {EntityType.parent | EntityType.region | EntityType.business | EntityType.site} entityType 
     * @param {Object[]} newEntities 
     */
    const updateAllEntitiesForType = (entityType, newEntities) => {
        switch (entityType) {
            case entityConfig.type.parent_company:
                setParentCompanies(newEntities.map(e => ({ ...e, id: e.parent_company_uuid })));
                break;
            case entityConfig.type.regional_company:
                setRegionalCompanies(newEntities.map(e => ({ ...e, id: e.regional_company_uuid })));
                break;
            case entityConfig.type.business_unit:
                setBusinessUnits(newEntities.map(e => ({ ...e, id: e.business_unit_uuid })));
                break;
            case entityConfig.type.business_unit_contact:
                setBusinessUnitContacts(newEntities.map(e => ({ ...e, id: e.business_unit_contact_uuid })));
                break;
            case entityConfig.type.site:
                setSites(newEntities.map(e => ({ ...e, id: e.site_uuid })));
                break;
            case entityConfig.type.site_contact:
                setSiteContacts(newEntities.map(e => ({ ...e, id: e.site_contact_uuid })));
                break;
            case entityConfig.type.representative_assignment:
                setRepresentativeAssignments(newEntities.map(e => ({ ...e, id: e.representative_assignment_uuid })));
                break;
            case entityConfig.type.customer_contract:
                setCustomerContracts(newEntities.map(e => ({ ...e, id: e.customer_contract_uuid })));
                break;
            case entityConfig.type.supplier_contract:
                setSupplierContracts(newEntities.map(e => ({ ...e, id: e.supplier_contract_uuid })));
                break;
            default:
                break;
        }
    }
    /**
     * Gets the service for a given entity type
     * @param {*} entityType 
     * @returns {{
     *       getAll: () => Promise<any>;
     *       getById: (id: any) => Promise<any>;
     *       create: (params: any) => Promise<any>;
     *       update: (id: any, params: any) => Promise<any>;
     *       delete: (id: any) => Promise<any>;
     * }}
     */
    const getService = (entityType) => {
        setIsLoading(true);
        switch (entityType) {
            case entityConfig.type.parent_company: return parentCompanyService;
            case entityConfig.type.regional_company: return regionalCompanyService;
            case entityConfig.type.business_unit: return businessUnitService;
            case entityConfig.type.site: return siteService;
            case entityConfig.type.site_contact: return siteContactService;
            case entityConfig.type.business_unit_contact: return businessUnitContactService;
            case entityConfig.type.representative_assignment: return representativeAssignmentService;
            case entityConfig.type.customer_contract: return customerContractService;
            case entityConfig.type.supplier_contract: return supplierContractService;
            default: return {
                getAll: (arg) => alert(`SERVICE_NOT_FOUND: -- ${entityType} -- getAll()`),
                getById: (arg) => alert(`SERVICE_NOT_FOUND: -- ${entityType} -- getById()`),
                create: (arg) => alert(`SERVICE_NOT_FOUND: -- ${entityType} -- create()`),
                update: (arg) => alert(`SERVICE_NOT_FOUND: -- ${entityType} -- update()`),
                delete: (arg) => alert(`SERVICE_NOT_FOUND: -- ${entityType} -- delete()`),
            };
        }
    };
    /**
     * Fetches all entities based on entity type.
     * @param {EntityType.parent | EntityType.region | EntityType.business | EntityType.site} entityType 
     */
    const fetchAll = (token, entityType) => {
        const service = getService(entityType);
        service.getAll(token)
            .then(response => {
                setIsLoading(false);
                updateAllEntitiesForType(entityType, response[entityType]);
            })
            .catch((err) => handleError(`Unable to get ${entityConfig.getDisplayName(entityType)}`, err))
    }
    /**
     * Creates a new entity based on the given entityType.
     * @param {Object} token
     * @param {EntityType.parent | EntityType.region | EntityType.business | EntityType.site} entityType 
     * @param {Object} data 
     */
    const createEntity = (token, entity, data) => {
        const entityType = typeof entity === "string" ? entity : entityConfig.getType(entity);
        
        const formData = data || entity;

        entityConfig.removeUuid(formData);
        const service = getService(entityType);
        service.create(token, formData)
            .then(res => {
                fetchAll(token, entityType);
                const parent = getParentEntity(formData);
                fetchAll(token, entityConfig.getType(parent));
                handleSuccess(`${entityConfig.getDisplayName(entityType)} created`);
                navigateTo(-1);
            })
            .catch((err) => {
              console.error(`CAC -Unable to create ${entityConfig.getDisplayName(entityType)}`, err)
              // handleError(`CAC -Unable to create ${entityConfig.getDisplayName(entityType)}`, err)
              navigateTo(-1);
            } 
          )
    }
    /**
     * Updates an entity based on the given entityType and uuid.
     * @param {Object} token
     * @param {EntityType.parent | EntityType.region | EntityType.business | EntityType.site} entityType 
     * @param {string} uuid 
     * @param {Object} data 
     */
    const updateEntity = (token, entity, entityUuid, data) => {
        const entityType = typeof entity === "string" ? entity : entityConfig.getType(entity);
        const formData = data || entity;
        const uuid = entityUuid || entityConfig.removeUuid(formData);

        const service = getService(entityType);
        service.update(token, uuid, formData)
            .then(res => {
                updateSingleEntityForType(formData);
                fetchAll(token, entityType);
                handleSuccess(`${entityConfig.getDisplayName(entityType)} updated`);
                navigateTo(-1);
            })
            .catch((err) => handleError(`Unable to update ${entityConfig.getDisplayName(entityType)}`, err))
    }
    /**
     * Deletes an entity based on entityType and uuid.
     * @param {Object} token
     * @param {EntityType.parent | EntityType.region | EntityType.business | EntityType.site} entityType 
     * @param {string} uuid to delete 
     * @returns {Promise<string|boolean>}
     */
    const handleRemoveItem = (token, entity, uuid) => {
        const entityType = typeof entity === "string" ? entity : entityConfig.getType(entity);
        const entityUuid = uuid || entityConfig.getUuid(entity);

        if (!entityUuid) {
            return alert("Cannot delete entity. Try refreshing this page");      
        }

        const service = getService(entityType);
        return service.delete(token, entityUuid)
            .then(() => {
                fetchAll(token, entityType);
                handleSuccess(`${entityConfig.getDisplayName(entityType)} deleted`);
                return entityUuid;
            })
            .catch((err) => {
                handleError(`Unable to delete. Still have ${entityConfig.getChildDisplayName(entityType, true)}?`, err)
                return false;
            })
    };

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
        fetchAll(token, entityConfig.type.parent_company);
        fetchAll(token, entityConfig.type.regional_company);
        fetchAll(token, entityConfig.type.business_unit);
        fetchAll(token, entityConfig.type.business_unit_contact);
        fetchAll(token, entityConfig.type.site);
        fetchAll(token, entityConfig.type.site_contact);
        fetchAll(token, entityConfig.type.representative_assignment);
        fetchAll(token, entityConfig.type.customer_contract);
        fetchAll(token, entityConfig.type.supplier_contract);
      })()
    }, [])

    // /* -------------------------------------------------------------------------- */
    // /*                              Navigation Utils                              */
    // /* -------------------------------------------------------------------------- */
    const params = useParams();
    const navigate = useNavigate();
    const navigateTo = (routeConfig, routeParams) => {
        if (typeof routeConfig === "number") {
            navigate(routeConfig);
            return;
        }
        let path = routeConfig?.fullPath || routeConfig?.path || "";
        const paramKeys = Object.keys(params);

        if (!path) {
            alert("Unable to navigate. Please check the provided route config to ensure it has a `path`.")
            return;
        }
        // replace route params first
        if (routeParams) {
            Object.keys(routeParams).forEach(paramKey => {
                path = path.replace(`:${paramKey}`, routeParams[paramKey]);
            })
        }
        // default to existing URL params if they are present
        if (paramKeys) {
            paramKeys.forEach(paramKey => {
                path = path.replace(`:${paramKey}`, params[paramKey]);
            })
        }
        navigate(path);
    };

    /**
     * Gets an entity's parent.
     * @param {*} entity 
     * @returns 
     */
    const getParentEntity = (entity) => {
        const entityType = entityConfig.getType(entity);
        switch (entityType) {
            case entityConfig.type.regional_company:
                return parentCompanies.find(pc => pc.parent_company_uuid === entity.parent_company_uuid);
            case entityConfig.type.business_unit:
                return regionalCompanies.find(rc => rc.regional_company_uuid === entity.regional_company_uuid);
            case entityConfig.type.business_unit_contact:
                return businessUnits.find(bu => bu.business_unit_uuid === entity.business_unit_uuid);
            case entityConfig.type.site:
                return businessUnits.find(bu => bu.business_unit_uuid === entity.business_unit_uuid);
            case entityConfig.type.site_contact:
                return sites.find(s => s.site_uuid === entity.site_uuid);
            case entityConfig.type.representative_assignment:
                return sites.find(s => s.site_uuid === entity.site_uuid);  
            case entityConfig.type.customer_contract:
                return sites.find(s => s.site_uuid === entity.site_uuid);
            case entityConfig.type.supplier_contract:
                return sites.find(s => s.site_uuid === entity.site_uuid);
            default:
                return undefined;
        }
    }
    /**
     * Gets downstream entities based on the given entityType.
     * - Parent Company
     * - Regional Company
     * - Business Unit
     * - Site
     * @param {*} entity 
     * @returns 
     */
    const getChildUuids = (entity) => {
        const entityType = entityConfig.getType(entity);
        let result = [entityConfig.getUuid(entity)];

        if (entityType === entityConfig.type.parent_company) {
            regionalCompanies
                .filter(r => r.parent_company_uuid === entity.parent_company_uuid)
                .forEach(r => result.push(...getChildUuids(r)))

        } else if (entityType === entityConfig.type.regional_company) {
            businessUnits
                .filter(b => b.regional_company_uuid === entity.regional_company_uuid)
                .forEach(b => result.push(...getChildUuids(b)))

        } else if (entityType === entityConfig.type.business_unit) {
            sites
                .filter(s => s.business_unit_uuid === entity.business_unit_uuid)
                .forEach(s => result.push(...getChildUuids(s)))
        }

        return result;
    }

    /**
     * Get's all parent UUIDs for the given entity
     * @param {*} entity 
     * @returns {[parent_uuid, ..., entity_uuid]} Array of all nested UUIDs
     */
    const getParentUuids = (entity) => {
        const parentEntity = getParentEntity(entity);
        if (!parentEntity) {
            return [entityConfig.getUuid(entity)];
        } else {
            return getParentUuids(parentEntity).concat(entityConfig.getUuid(entity));
        }
    }

    const getHierarchyParams = (entity) => {
        const hierarchyUuids = getParentUuids(entity);
        return {
            parent_company_uuid: hierarchyUuids[0],
            regional_company_uuid: hierarchyUuids[1],
            business_unit_uuid: hierarchyUuids[2],
            site_uuid: hierarchyUuids[3],
        }
    }

    /* -------------------------------------------------------------------------- */
    /*                             Active Entity State                            */
    /* -------------------------------------------------------------------------- */
    const [activeParent, setActiveParent] = useState(null);
    const [activeRegion, setActiveRegion] = useState(null);
    const [activeBusiness, setActiveBusiness] = useState(null);
    const [activeBusinessContact, setActiveBusinessContact] = useState(null);
    const [activeSite, setActiveSite] = useState(null);
    const [activeSiteContact, setActiveSiteContact] = useState(null);
    const [activeRepresentativeAssignment, setActiveRepresentativeAssignment] = useState(null);
    const [activeCustomerContract, setActiveCustomerContract] = useState(null);
    const [activeSupplierContract, setActiveSupplierContract] = useState(null);

    /* ----- Set active entity when its list state changes or params change ----- */
    useEffect(() => {
        setActiveParent(parentCompanies.find(parent => parent.parent_company_uuid === params.parent_company_uuid))
    }, [params.parent_company_uuid, parentCompanies]);

    useEffect(() => {
        setActiveRegion(regionalCompanies.find(region => region.regional_company_uuid === params.regional_company_uuid))
    }, [params.regional_company_uuid, regionalCompanies]);

    useEffect(() => {
        setActiveBusiness(businessUnits.find(unit => unit.business_unit_uuid === params.business_unit_uuid))
    }, [params.business_unit_uuid, businessUnits]);

    useEffect(() => {
        setActiveBusinessContact(businessUnitContacts.find(unit => unit.business_unit_contact_uuid === params.business_unit_contact_uuid))
    }, [params.business_unit_contact_uuid, businessUnitContacts]);

    useEffect(() => {
        const newSite = sites.find(site => site.site_uuid === params.site_uuid)
        setActiveSite(newSite)
    }, [params, sites]);

    useEffect(() => {
        if (activeSiteContact?.site_contact_uuid !== params.site_contact_uuid)
            setActiveSiteContact(siteContacts.find(site => site.site_contact_uuid === params.site_contact_uuid))
    }, [params.site_contact_uuid, siteContacts]);

    useEffect(() => {
      if (activeRepresentativeAssignment?.representative_assignment_uuid !== params.representative_assignment_uuid)
          setActiveRepresentativeAssignment(representativeAssignments.find(representativeAssignment => representativeAssignment.representative_assignment_uuid === params.representative_assignment_uuid))
    }, [params.representative_assignment_uuid, representativeAssignments]);
    
    useEffect(() => {
      if (activeCustomerContract?.customer_contract_uuid !== params.customer_contract_uuid)
          setActiveCustomerContract(customerContracts.find(customerContract => customerContract.customer_contract_uuid === params.customer_contract_uuid))
    }, [params.customer_contract_uuid, customerContracts]);

    useEffect(() => {
      if (activeSupplierContract?.supplier_contract_uuid !== params.supplier_contract_uuid)
          setActiveSupplierContract(supplierContracts.find(supplierContract => supplierContract.supplier_contract_uuid === params.supplier_contract_uuid))
    }, [params.supplier_contract_uuid, supplierContracts]);

    const setActive = async (entityType, uuid) => {
        const token = await getAccessTokenSilently();
        getService(entityType)
            .getById(token, uuid).then(res => {
                const newEntity = res[entityType];
                updateSingleEntityForType(entityType, newEntity);
                setIsLoading(false);
                switch (entityType) {
                    case entityConfig.type.parent_company:
                        setActiveParent(newEntity);
                        break;
                    case entityConfig.type.regional_company:
                        setActiveRegion(newEntity);
                        break;
                    case entityConfig.type.business_unit:
                        setActiveBusiness(newEntity);
                        break;
                    case entityConfig.type.site:
                        setActiveSite(newEntity);
                        break;
                    default:
                        break;
                }
            });
    };

    return <CustomerAdminContext.Provider
        value={{
            isLoading: isLoading,
            dev_control: defaultValue.dev_control,
            entities: {
                parent: parentCompanies,
                region: regionalCompanies,
                business: businessUnits,
                business_unit_contact: businessUnitContacts,
                site: sites,
                site_contact: siteContacts,
                representative_assignment: representativeAssignments,
                customer_contract: customerContracts,
                supplier_contract: supplierContracts,
                active_parent: activeParent,
                active_region: activeRegion,
                active_business: activeBusiness,
                active_business_unit_contact: activeBusinessContact,
                active_site: activeSite,
                active_site_contact: activeSiteContact,
                active_representative_assignment: activeRepresentativeAssignment,
                active_customer_contract: activeCustomerContract,
                active_supplier_contract: activeSupplierContract,
            },
            setActiveEntity: setActive,
            windowDimensions: windowDimensions,
            navigateTo: navigateTo,
            createEntity: createEntity,
            deleteEntity: handleRemoveItem,
            updateEntity: updateEntity,
            getChildUuids: getChildUuids,
            getHierarchyParams: getHierarchyParams,
            params: params,
        }}
    >
        {props.children}
    </CustomerAdminContext.Provider>
}

export default CustomerAdminContext;    